import './scss/main.scss'

import $ from 'jquery';

import 'slick-carousel'

import AOS from 'aos';
import 'aos/dist/aos.css';

import { ScrollScene, addIndicators } from 'scrollscene'
import gsap, { TimelineMax, TweenMax, TweenLite } from 'gsap'

const tlImageHeader = gsap.timeline({ paused: true })

tlImageHeader.to('.fabrica .foto', {
    opacity: 1
})
tlImageHeader.fromTo('.fabrica .foto', { opacity: 1 }, { opacity: 1 })

const scrollScene = new ScrollScene({
    triggerElement: '.boc',
    duration: 200,
    gsap: {
      timeline: tlImageHeader
    }
})

//scrollScene.Scene.setClassToggle(".boc", "myclass");
scrollScene.Scene.on("start", function (event) {
    $(".fabrica .foto").addClass("active");
});
//scrollScene.Scene.addIndicators({ name: 'pin scene', colorEnd: '#FFFFFF' })

$("#sim").click(function(){
    $(".modal").addClass("disabeld")
    setTimeout(function(){
        $("header h1").addClass("active");
        $(".words").addClass("active");
    },1000)
    $("body").css("overflow", "auto");
    return false;
});

$("#nao").click(function(){
    window.close()
    return false;
});

$(document).on('click', 'a.row-scroll', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 1000);
});

$('.produtos .slide').slick({
    dots: true,
    autoplay: false,
    fade: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow:'<button type="button" class="slick-prev pull-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z"/></svg></button>',
    nextArrow:'<button type="button" class="slick-next pull-right"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/></svg></button>'
});

$('.instagram .slide').slick({
    dots: true,
    autoplay: false,
    fade: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow:'<button type="button" class="slick-prev pull-left"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z"/></svg></button>',
    nextArrow:'<button type="button" class="slick-next pull-right"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/></svg></button>'
});

AOS.init({
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 10, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 2000, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
});